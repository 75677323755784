var w = window,
d = document,
e = d.documentElement,
g = d.getElementsByTagName('body')[0],
widthviewport = w.innerWidth || e.clientWidth || g.clientWidth,
heightviewport = w.innerHeight || e.clientHeight || g.clientHeight;

$(document).ready(function(){

	$('.menu--burger').on('click', function(){
		$(this).toggleClass('active');
		$('body').toggleClass('sidebar-active');

		// HOME BANNER
		$('.banner-slider').slick('unslick');
		$('.banner-slider').slick();
	});

	$('.m--header').on('click', '.isparent', function(){
		if(!$(this).parent().hasClass('active')){
			$(this).parent().addClass('active');
		}
		else{
			$(this).parent().removeClass('active');	
		}
	});



	$(".chosen").chosen({disable_search_threshold: 10});


	// bg responsive
	if (widthviewport > 768){
		$(".bg-responsive").each(function(){
			var getbg_d = $(this).attr("bg-d");
			$(this).css("cssText","background-image:url("+getbg_d+")");
		});
	}else{
		$(".bg-responsive").each(function(){
			var getbg_m = $(this).attr("bg-m");
			$(this).css("cssText","background-image:url("+getbg_m+")");
		});
	}


	// show unit spec
    $(".show-unitspec").click(function(e){
    	e.preventDefault();
    	var getut = $(this).attr("href");
    	if($(getut).hasClass("active")){
    		$(getut).removeClass("active");
    		$(this).removeClass('collapse');
    	}else{
    		$(getut).addClass("active");
    		$(this).addClass('collapse');
    	}
    });

    // show more unit types
	$(".btn--moreut").on("click", function(e){
		e.preventDefault();
	    $(".unit__list").addClass("active");
	    $(this).css("cssText","display:none !important;");
    });

    $('.slider--readystock').slick({
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});


	$('.slimscroll').slimScroll({
        size: '4px'
    });

    $('.match-height').matchHeight();
});


// module card collapse mobile 
+function($) {

	var CardCollapse = function(element) {
		this.$element = $(element)
		this.$trigger = $('[data-toggle-q="collapse"][href="#' + element.id + '"],' +
			'[data-toggle-q="collapse"][data-target="#' + element.id + '"]')
	}

	CardCollapse.VERSION = '0.0.1'

	CardCollapse.AUTHOR = 'thofikwiranata15@gmail.com'

	CardCollapse.prototype.show = function() {
		var $target = getTarget(this.$trigger)

		checkActived(this)

		$target.css({display: 'block'})
		setTimeout(function(){ 
			$target.addClass('active')
		}, 50);


		function checkActived($instance) {

			$targets = $('.card-collapse__content', document) // all target elements

			$targets.each(function() {

				if($(this).hasClass('active')) {
					$instance.toggleText($(this)) 
					return 
				}
				
			})

			$targets
			.removeClass('active')
			.css('display', 'none')
			return
		}
	}

	CardCollapse.prototype.hide = function() {
		var $target = getTarget(this.$trigger)
		$target.removeClass('active')
		setTimeout(function(){ 
			$target.css('display', 'none')
		}, 200);
	}

	CardCollapse.prototype.toggle = function() {
		var $target = getTarget(this.$trigger)
		var state = $target.hasClass('active') ? true : false 

		this.toggleText(this.$element)

		if (state) 
			this.hide()
		else 
			this.show()

	}

	CardCollapse.prototype.toggleText = function($target) {
		var $parent = $target.parent()
		var $elMore = $parent.find('.card-collapse__thumbnail__show')
		var text = getDataText()
		setDataText(text)

		// animate arrow 
		animateArrow()

		function getDataText() {
			return $elMore.attr('data-text')
		}

		function setDataText(text) {
			var elText = $elMore.text()
			$elMore.attr('data-text', elText)
			$elMore.find('span').text(text)
		}

		function animateArrow() {
			var isActive = $parent.hasClass('animate-arrow') ? true : false

			if(isActive)
				$parent.removeClass('animate-arrow')
			else 
				$parent.addClass('animate-arrow')

			return
		}
	}

	function getTarget($trigger) {
		var href 
		var target = $trigger.attr('data-target')
		|| (href = $trigger.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '')

		return $(target)
	}


	// CARD COLLAPSE PLUGIN DEFINITION
	function Plugin(option) {
		return this.each(function () {
			var $this   = $(this)
			var data    = $this.data('q.cardcollapse')
			var options = $.extend({}, $this.data(), typeof option == 'object' && option)

			var cardCollapse = new CardCollapse(this, options)
			if (!data) $this.data('q.cardcollapse', (data = cardCollapse))
				if (typeof option == 'string') data[option]()
					cardCollapse.toggle()
			})
	}

	$.fn.collapse             = Plugin
	$.fn.collapse.Constructor = CardCollapse

	$(document).on('click.q.cardcollapse.data-api', '[data-toggle-q="collapse"]', function (e) {
		var $this   = $(this)

		if (!$this.attr('data-target')) e.preventDefault()

			var $target = getTarget($this)
		var data    = $target.data('q.collapse')
		var option  = data ? 'toggle' : $this.data()
		Plugin.call($target, option)
	})

}(jQuery)



// GOOGLE MAP CUSTOM
// 	var map;
// 	function initMap() {
// 	    map = new google.maps.Map(document.getElementById('map-contact'), {
// 	      zoom: 16,
// 	      center: new google.maps.LatLng(-6.3144561, 106.73230112),
// 	      mapTypeId: 'roadmap',
// 	      disableDefaultUI: true
// 	    });

// 	    var iconBase = '/images/cluster/voyage/map/';
// 	    var icons = {
// 	      cluster: {
// 	        icon: iconBase + 'logo-paradiseresort.png'
// 	      }
// 	    };


// 	    var features = [      
// 		    {
// 		    	position: new google.maps.LatLng(-6.31629026, 106.73202217),
// 		    	type: 'cluster'
// 		    }
// 	    ]

// 	    var myLatLng = {lat: -6.31413619, lng: 106.73230112};
// 	    var marker = new google.maps.Marker({
// 		    position: myLatLng,
// 		    map: map,
// 		    title: 'Hello World!'
// 	    });


// 	    // Create markers.
// 	    features.forEach(function(feature) {
// 	      var marker = new google.maps.Marker({
// 	        position: feature.position,
// 	        icon: icons[feature.type].icon,
// 	        map: map
// 	      });
// 	    });


// 	    var styledMapType = new google.maps.StyledMapType(
// 		[
// 		 {
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#eaeaea"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "elementType": "labels.icon",
// 		    "stylers": [
// 		      {
// 		        "visibility": "off"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#616161"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "elementType": "labels.text.stroke",
// 		    "stylers": [
// 		      {
// 		        "color": "#f5f5f5"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "administrative.land_parcel",
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#bdbdbd"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "poi",
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#eeeeee"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "poi",
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#757575"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "poi.park",
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#e5e5e5"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "poi.park",
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#9e9e9e"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "road",
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#ffffff"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "road.arterial",
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#757575"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "road.highway",
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#dadada"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "road.highway",
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#616161"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "road.local",
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#9e9e9e"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "transit.line",
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#e5e5e5"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "transit.station",
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#eeeeee"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "water",
// 		    "elementType": "geometry",
// 		    "stylers": [
// 		      {
// 		        "color": "#a1cbff"
// 		      }
// 		    ]
// 		  },
// 		  {
// 		    "featureType": "water",
// 		    "elementType": "labels.text.fill",
// 		    "stylers": [
// 		      {
// 		        "color": "#9e9e9e"
// 		      }
// 		    ]
// 		  }
// 		],{name: 'Styled Map'});

// 	    //Associate the styled map with the MapTypeId and set it to display.
// 		map.mapTypes.set('styled_map', styledMapType);
// 		map.setMapTypeId('styled_map');
// 	}

// var ismap = document.getElementsByClassName('pgaddress');
// if (ismap.length > 0){

// }